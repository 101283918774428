import Theaxios from 'axios'
// import router from '@/router/index'
import { Toast, Dialog } from 'vant';
// import { refreshTokenAPI } from '@/api/index'
import router from '@/router'
import { getInfo, getCode, getToken, removeInfo, removeToken, removeCode } from '@/utils/auth'

const axios = Theaxios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 20000 // 设置超时时间
})

// 添加请求拦截器
axios.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  // console.log(config)
  // 让请求的时候必须带上token
  // ?. 是链式操作符，当getToken()中没有length,整个表达式为undefined
  // 当getToken有length才执行 .length 来获取长度
  if (getToken()) {
    config.headers.AccessToken = getToken()
    // config.headers.RoleId = JSON.parse(getInfo()).role_id
    // config.headers.RouterPath = `admin${router.history.current.path}`
  }
  if (getCode()) {
    config.headers.Code = getCode()
  }
  // do something before request is sent
  if (getInfo()) {
    // if(config.method == 'get' || config.method == 'delete'){
    //   config.params['uid'] = JSON.parse(getInfo()).id
    // }else if(config.data !== null){
    //   config.data['uid'] = JSON.parse(getInfo()).id
    // }
  } else {
    // router.push('/login')
  }
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 添加响应拦截器
axios.interceptors.response.use(
  response => {
    const res = response.data
	console.log(res);
    if (res.code === 20001) {
      reLogin(res)
    } else if (res.code !== 0) {
      Toast({
        message: res.msg || 'Error',
        type: 'error'
      })
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    Toast.fail({
      message: error.msg,
    })
    // 对响应错误做点什么
    return Promise.reject(error)
  }
)

function reLogin(res) {
  Dialog.alert({
    message: res.msg
  }).then(() => {
    removeInfo()
    removeToken()
    removeCode()
    this.$router.push({
      path: '/'
    })
  }).catch(() => {})
}

export default ({ url, method = 'GET', params = {}, data = {}, headers = {} }) => {
  return axios({
    url,
    params,
    method,
    data,
    headers
  })
}
